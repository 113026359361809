import ApplicationController from './application_controller'

export default class extends ApplicationController {
	connect() {
		//dont connect to reflexes
	}
		
	// close() {
	// 	let area = document.querySelector('#authorization_area')
	// 	area.classList.add('display-none')
	// }
	// open() {
	// 	let area = document.querySelector('#authorization_area')
	// 	if( area.classList.contains('display-none')) {
	// 		area.classList.remove('display-none')
	// 	} else {
	// 		document.tripwire('/users/pick_intent', {
	// 				form_info: {morphing: 'true'}
	// 			})
	// 	}
	// }
	// tripwire_intercept(e){
	// 	let context = this
	// 	super.tripwire_intercept(e).finally(() => {
	// 		context.kill_any_loading_butts_below(context.element)
	// 	})
	// }
}