import ApplicationController from './application_controller'
export default class extends ApplicationController {
	static targets = ["cardContainer"];
	connect () {
		this.moveImages()
	}

	traverseAndTrim(node, remainingLength) {
		//- node.children is undefined for text nodes.
		//- text nodes are not shown in a nodes node.children, only in node.childNodes.
		if(! node.children) {
			let text = node.textContent;
			if(text.length >= remainingLength) {
				node.textContent = node.textContent.slice(0, remainingLength) + "…"
				remainingLength = -1
			} else {
				remainingLength = remainingLength - text.length
			}
		} else {
			for(let c of Array.from(node.childNodes)) {
				if(c.children && c.classList.contains('d-inline-block')){
					continue
				} else if(remainingLength <= 0){
					c.remove()
				} else {
					remainingLength = this.traverseAndTrim(c, remainingLength)
				}
			}
		}
		return remainingLength
	}

	async moveImages(){
		let proms = []
		for(let im of Array.from(this.element.querySelectorAll('img'))){
			proms.push(
				new Promise((resolve) => {
					im.onload = resolve
					if(im.complete){resolve()}
				})
			)
		}
		for(let p of proms){ await p }
		let cc = this.cardContainerTarget
		let textLength = 125;
		let img = cc.qs(".post_card_img");
		let sideBySide = cc.qs(".sideBySide");
		let content = cc.qs(".post_card_text_short")
		let title = cc.qs(".post-header-row")
		
		if(! content){
			content = cc.qs(".post_card_text_tiny")
			textLength = 100
		}
		if (img && sideBySide){
			let ratio = (img.naturalHeight / img.naturalWidth)
			console.log(ratio)
			if(ratio < 1.1){
				console.log("ratio<1.1")
				sideBySide.classList.add("sideBySideSyke")
				this.traverseAndTrim(content, textLength);
				title.classList.remove("hasSideImage")
			}
			else{
				this.adjustText()
			}
		}
	}

	adjustText(){
		const cc = this.cardContainerTarget;
		const img = cc.querySelector(".post_card_img");
		const imgContainer = cc.querySelector(".post_card_img_container");
		const content = cc.querySelector(".post_card_text_short") || cc.querySelector(".post_card_text_tiny");
		const title = cc.querySelector(".post-header-row");

		// Get current viewport width
		const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

		// Determine adjustment based on breakpoints
		let adjustment;
		if (viewportWidth >= 2000) {
			adjustment = 2; // $huge-desktop
		} else if (viewportWidth >= 1600) {
			adjustment = 2; // $large-desktop
		} else if (viewportWidth >= 1200) {
			adjustment = 2; // $big-desktop
		} else if (viewportWidth >= 1000) {
			adjustment = 2; // $desktop
		} else if (viewportWidth >= 800) {
			adjustment = 6; // $small-desktop
		} else if (viewportWidth >= 720) {
			adjustment = 6; // $tablet
		} else {
			adjustment = 8; // $phone and smaller
		}

		// Align image with the top of the title
		const titleTop = title.getBoundingClientRect().top;
		const imgContainerTop = imgContainer.getBoundingClientRect().top;
		const topAdjustment = imgContainerTop - titleTop + adjustment;
		imgContainer.style.marginTop = `${-topAdjustment}px`;

		// Calculate available height for text
		const imageBottom = img.getBoundingClientRect().bottom;
		const contentTop = content.getBoundingClientRect().top;
		const availableTextHeight = imageBottom - contentTop;

		// Trim text to fit available height
		const contentStyles = window.getComputedStyle(content);
		const lineHeight = parseFloat(contentStyles.lineHeight);
		const fontSize = parseFloat(contentStyles.fontSize);
		const maxLines = Math.floor(availableTextHeight / lineHeight);
		const charsPerLine = Math.floor(content.offsetWidth / (fontSize * 0.42));
		
		// Reduce maxChars slightly to ensure we trim enough
		const maxChars = Math.floor(maxLines * charsPerLine * 0.92); // 5% reduction

		const originalText = content.textContent;
		if (originalText.length > maxChars) {
			this.traverseAndTrim(content, maxChars);
			
			// Check if we need to trim a little more
			if (content.offsetHeight > availableTextHeight) {
				const charsToRemove = 8; // Remove slightly more than one line
				this.traverseAndTrim(content, maxChars - charsToRemove);

			}
		}


		// console.log({
		// 	"title": title.textContent,
		// 	"contentHeight": content.offsetHeight,
		// 	"availableTextHeight": availableTextHeight,
		// 	"difference": content.offsetHeight - availableTextHeight,
		// 	"contentLineHeight": parseFloat(window.getComputedStyle(content).lineHeight),
		// 	"contentStyle": window.getComputedStyle(content)
		// });

		// console.log({
		// 	"title": title.textContent,
		// 	"Viewport Width": viewportWidth,
		// 	"adjustment": adjustment,
		// 	"Title Top": titleTop,
		// 	"Image Container Top": imgContainerTop,
		// 	"Top Adjustment": topAdjustment,
		// 	"Available Text Height": availableTextHeight,
		// 	"Max Lines": maxLines,
		// 	"Max Chars": maxChars,
		// 	"chars per line": charsPerLine,
		// 	"Original Text Length": originalText.length,
		// 	"Final Text Length": content.textContent.length,
		// 	"Final Content Height": content.offsetHeight
		// });
	}

}