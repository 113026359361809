
//enable service worker in dev
//	chrome: http: chrome://flags/#unsafely-treat-insecure-origin-as-secure -> http://localhost.io
//	firefox: devtools.serviceWorkers.testing.enabled

//https://onrails.blog/2021/03/02/rails-pwas-using-turbo-hhnpwa-7/
import ApplicationController from './application_controller'
export default class extends ApplicationController {
	connect() {
		if (navigator.serviceWorker) {
			//all the versions go to the same place, its just a way for the thing to update.
			let gotoo = `/service-worker-${midflip.serviceWorkerVersion}.js`

			// Registers (installs or updates) the service worker, and wait for it to become active
			navigator.serviceWorker.register(
					gotoo, { scope: "/"} //, updateViaCache: 'imports' 
				).then( (reg) => {
					console.log("Service worker registered!", reg);
				}).catch( (error) => {
					if(midflip.isDev) {
						alert(error + '| only shows in development')
					}
					console.log(error)
				});

			// if (navigator.serviceWorker.controller) {
			// 	// If the service worker is already running, skip to state change
			// 	this.stateChange();
			// } else {
			// 	//i dont know what this does
			// 	navigator.serviceWorker.addEventListener(
			// 		"controllerchange",
			// 		this.controllerChange.bind(this)
			// 	);
			// }

		} else {
			console.log('serviceWorker unavailable')
		}
	}

	// fuck notifications i hope they fucking die
	//
	// stop_notifications(){
	// 	context.stimulate("Open#service_worker_push_sub", 'false').then(() => {
	// 		document.getElementById('notification_issue_area').innerHTML = "Notifications Disabled"
	// 	})
	// }

	// get_notifications(){
	// 	try {
	// 		//requests notification permissions and then sets up push notifications
	// 		let context = this
	// 		let sub_opts = {
	// 			userVisibleOnly: true,
	// 			applicationServerKey: new Uint8Array([
	// 				4, 248, 192, 191, 46, 158, 66,  238,
	// 				120, 229, 0, 175, 251, 39, 11,  101,
	// 				57, 107, 236, 54, 63, 38, 31,   101,
	// 				186, 220, 28, 242, 31, 109, 21,  65,
	// 				113, 16, 75, 124, 184, 22, 13,    3,
	// 				221, 155, 57, 252, 71, 122, 176, 87,
	// 				32, 244, 85, 149, 8, 123, 91,   161,
	// 				156, 237, 11, 116, 94, 68, 154, 242,
	// 				223
	// 			])
	// 		}

	// 		if(!('serviceWorker' in navigator)){
	// 			console.log('service worker is not supported')
	// 		}
	// 		if(!('PushManager' in window)) {
	// 			console.log("Push manager is not supported")
	// 		}

	// 		console.log(1)
	// 		Notification.requestPermission().then((result) => {
	// 			console.log(2)
	// 			if (result === 'granted') {
	// 				navigator.serviceWorker.ready.then((serviceWorkerRegistration)=>{				
	// 					//THIS CODE IS ALSO IN THE SERVICE_WORKER.JS.ERB FILE
	// 					console.log("SERVICE WORKER REGISTRATION", serviceWorkerRegistration)
	// 					// get the push notifications going
	// 					// https://github.com/pushpad/web-push
	// 					// .ready just waits until the service worker is subbed.
	// 					if(serviceWorkerRegistration.pushManager != undefined) {
	// 						console.log('bout ta f up')
	// 						serviceWorkerRegistration.pushManager.subscribe(sub_opts).then(
	// 							(push_sub) => {		//handle sub
	// 								console.log(8)
	// 								//send push information backend
	// 								context.stimulate("Open#service_worker_push_sub", JSON.stringify(push_sub))
	// 								console.log(9)
	// 								document.getElementById('notification_issue_area').innerHTML = "You have subscribed to push notifications!"
	// 							console.log(10)
	// 							},
	// 							(error) => {		//handle wtf
	// 								console.log(8.1)
	// 								console.log(error)
	// 							},
	// 						).finally(
	// 							() => {				//make sure it even finishes i am so done.
	// 								console.log(8.2)
	// 							}
	// 						);
	// 					} else {
	// 						console.log(5)
	// 						document.getElementById('notification_issue_area').innerHTML = "It seems the browser does not support push notifications - Perhaps try using chrome"
	// 					}
	// 				});
	// 			} else {
	// 				console.log(6)
	// 				document.getElementById('notification_issue_area').innerHTML = "The browser has blocked our request for notification access"
	// 			}
	// 		})
	// 	} catch (error) {
	// 		console.log(-1)
	// 		console.log("e", error)
	// 	}
	// }

	controllerChange(event) {
		navigator.serviceWorker.controller.addEventListener(
			"statechange",
			this.stateChange.bind(this)
		);
	}
	stateChange() {
		
	}
}
