
//this file contains all the code for Unit Handling (data-unit, data-ex). It also includes shortcuts
//for element access.
//
//we:
//	1. create shortcuts for easier access to important html funcionality (currentTarget = ct, dataset = ds, e.currentTarget.dataSet.x = e.ct.ds.x).
//		These attributes get used constantly, this helps think about the more important bits.
//	2. Accept a bunch of custom units.
//	3. Initial load those units and event handlers
//	4. Create observers for those attributes and keep it updated.




import UnitHandler from '@luketclancy/otterly/unit_handler'
export default UnitHandler