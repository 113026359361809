import ApplicationController from './application_controller'

export default class extends ApplicationController {
    connect() {
		let conv_id = this.element.dataset.conversationId
		document.tripwire_subscribe(["UserChannel", "ConversationUpdateChannel"], {'conversation-id': conv_id}, 1000)		
    }
	disconnect() {
		document.tripwire_subscribe(["UserChannel"], {}, 5000)
	}
}