import ApplicationController from './application_controller'
import TimeMe from 'timeme.js'


export default class extends ApplicationController {
	connect() {
		TimeMe.resetAllRecordedPageTimes();
		TimeMe.initialize({
			currentPageName: window.location.pathname,
			idleTimeoutInSeconds: 45, //since we are long form in many cases
		})
		this.start_t = new Date().getTime()
		this.bef_path = window.location.href //can switch on pat
		
		this.post_times = this.post_times.bind(this) //<- just always be good plz
		window.addEventListener('beforeunload', this.post_times)//<- this is when they press the x button. disconnect does not get called in that case.
	}
	post_times(){
		// console.log(this.bef_path, this.start_t, this.application, 'aaa')
		document.quick_xhr('/api/times', {
			path: this.bef_path,
			active_t: TimeMe.getTimeOnCurrentPageInSeconds(),
			start_t: this.start_t,
			tid: midflip.getTabId()
		})
	}
	disconnect() {
		window.removeEventListener('beforeunload', this.post_times)
		this.post_times() //<- SPA
	}
}