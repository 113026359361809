
export default {
	unitName: "GoogleButton",
	onConnected(){
		//the load_button thing is gross but for some reason I kept having issues and
		//this is what it ended up with after it started working.
		//Holy fuck google fine overkilltime
		this.obs = midflip.mob(this.el, (() => {
			let fr = this.el.qs('iframe')
			console.log(fr)
			if(fr.getAttribute('style').includes('-2px -10px;')){
				fr.setAttribute('style',
					(fr.getAttribute('style').
						split(';').
						filter((a) => {
							return !(a.includes('width') || a.includes('margin'))
						}).
						join(';')
					) + `; width: ${parseInt(this.el.dataset.width) + 20}px; margin: -2px -10px 0px 0px;`
				)
			}
		}).bind(this))
		
		let head = document.querySelector('script')
		let script = document.createElement('script')
		let load_button = this.f.bind(this)

		script.type = 'text/javascript'
		script.src = 'https://accounts.google.com/gsi/client'
		script.async = false
		script.addEventListener('load', load_button)
		head.appendChild(script)

		setTimeout(load_button, 1300)
		load_button()
	},
	onRemoved(){
		if(this.obs){this.obs.disconnect()}
	},
	f(){
		if(this.ran || !(window.google)){ //must be window.google because if it just google then its undefined and we error 
			console.log("failure, google: ", window.google, ", ran: ", this.ran)
			return
		}
		this.ran = true
		google.accounts.id.initialize({
				client_id: this.el.dataset.client_id,
				callback: (x) => {
					//the login_uri option just randomly stopped working so we do this callback thing with a dive.
					let credential = x.credential
					console.log('your cred is', credential)
					otty.dive({url: this.el.dataset.login_uri, formInfo: {credential}})
				},
				auto_prompt: "false"
			});
		google.accounts.id.renderButton( this.el,  {
				theme: "outline",
				width: parseInt(this.el.dataset.width),
				type: "standard",
				size: "large",
				theme: "outline",
				text: "sign_in_with",
				shape: "rectangular",
				logo_alignment: "left"
			});
		google.accounts.id.prompt(); 
	}
}