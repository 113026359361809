import morphdom from 'morphdom'
function tripwire_init(is_dev) {

	class TripwireActions {
		constructor(base_element, submitter, resolve, reject, development){
			this.base_element = base_element
			this.submitter = submitter
			this.resolve = resolve
			this.reject = reject
			this.development = development
		}
		get_selector(str) {
			if(str == "submitter") {
				return this.submitter
			} else if (str == "base_element") {
				return this.base_element
			} else {
				return document.querySelector(str)
			}
		}
		log(obj){
			console.log(obj)
		}
		reload(){
			otty.goto(window.location.href)
		}
		redirect(obj){
			otty.goto(obj)
		}
		insert(obj){
			let sel = this.get_selector(obj['selector'])
			if(sel == null && this.development) {
				console.log('missing selector ', obj['selector'])
			}
			let pos = obj['position']
			let html = obj['html']
			sel.insertAdjacentHTML(pos, html)
		}
		morph(x) {
			let opts = x //this includes options that go directly to the morphdom function
			let perm = x['permanent']
			let ign = x['ignore']
			if(opts == null) {
				opts = {}
			}
			if(ign != null || perm != null) {
				let m_parse_p = (selector, from, to) => {
					if(from.matches(selector) && to.matches(selector)) {
						return false
					} else {
						return true
					}
				}
				let m_parse_i = (selector, from, to) => {
					if(from.matches(selector) || to.matches(selector)) {
						return false
					} else {
						return true
					}
				}
				let m_parse = (selectors, inner_parse, from, to) => {
					if(!(Array.isArray(selectors))) {
						selectors = [selectors]
					}
					for(let y = 0; y < selectors.length; y++) {
						if(!inner_parse(selectors[y], from, to)){
							return false
						}
					}
					return true
				}
				opts['onBeforeElChildrenUpdated'] = (from, to) => {
					if(! m_parse(ign, m_parse_i, from, to) ) {
						return false
					}
					if(! m_parse(perm, m_parse_p, from, to) ) {
						return false
					}
					return true
				}
			}
			let s = this.get_selector(x['selector'])
			if(s ==null && this.development) {
				console.log('missing selector ', x['selector'])
				return
			}
			morphdom(s, x['html'], opts)
		}
		remove(obj) {
			let s = this.get_selector(obj['selector'])
			s.parentNode.removeChild(s)
		}
		replace(obj){
			//this method needs more scrutiny
	
			//does not support base_element or submitter selectors. Recommend using ids.
			let sel, parser, tempdoc, orienter, children_only
	
			parser = new DOMParser();
			tempdoc = parser.parseFromString(obj['html'],  "text/html")
			orienter = tempdoc.querySelector(obj['selector'])
			children_only = obj['children_only']
			sel = document.querySelector(obj['selector'])
			if(sel == null && this.development) {
				console.log('missing selector ', obj['selector'])
				return
			}
	
			if(orienter == null) {
				if(children_only) {
					sel.innerHTML = obj['html']
				} else {
					orienter = tempdoc.querySelector('body').children[0]
					sel.replaceWith(orienter)
				}
			} else {
				if(children_only) {
					sel.innerHTML = orienter.innerHTML
				} else {
					sel.replaceWith(orienter)
				}
			}
		}
		inner_html(obj) {
			let s = this.get_selector(obj['selector'])
			if(s == null && this.development) {
				console.log('missing selector ', obj['selector'])
				return
			}
			s.innerHTML = obj['html']
		}
		eval2(data) {								//anything weird? Just use this. You have access to anything in the hash.
													//selector input is special and will automatically be set to the variable referenced.
			let selector							//note it doesn't actually use eval for performance related reasons. Something about effects on minimization i believe.
			if(data['selector']){					//is this a security risk? We check we are connecting with ourselves above. So should be fine
				selector = this.get_selector(data['selector'])
			}
			//continue being insane lol
			let x = Function("data", "selector", 'base_element', 'submitter', `"use strict"; ${data['code']};`)(data, selector, this.base_element, this.submitter)
			if(x == "break") {						//lil bit of extra awesome
				resolve(returning)
				return "break"
			}
		}
		set_form_data(sfd){
			let f, els, key, keys, value, i, x
			if(sfd['form'] != null) {
				f = this.get_selector(sfd['form'])
			} else {
				f = this.submitter.form
			}
			if(f == null) {return}
			//gid://midflip/Comment/A7tEoD@form
			els = f.elements
			keys = Object.keys(sfd)
			for(x = 0; x < keys.length; x++) {
				key = keys[x]
				value = sfd[key]
				i = els.namedItem(key)
				if(value == false || value == null || value.toLowerCase() == 'false') {
					if(!(i == null)) {
						i.remove()
					}
				}
				else if(i == null) {
					i = document.createElement('input')
					i.setAttribute('name', key)
					i.setAttribute('value', sfd[key])
					i.setAttribute('type', 'hidden')
					f.insertAdjacentElement('afterbegin', i) //neeter after beginning
				} else {
					i.setAttribute('value', sfd[key])
				}
			}
		}
		set_data(data){
			let keys, x, key, obj, attrs, attr_keys, y, attr_key
			keys = Object.keys(data)
			for(x = 0; x < keys.length; x++) {
				key = keys[x]
				obj = this.get_selector(key)
				attrs = data[key]
				attr_keys = Object.keys(attrs)
				for(y = 0; y < attr_keys.length; y++) {
					attr_key = attr_keys[y]
					obj.dataset[attr_key] = attrs[attr_key]
				}
			}
		}
	}

	document.obj_to_fd = function(form_info) {
		if(form_info instanceof FormData) {
			return form_info
		} else {
			//get good 🦄
			let recursed = (form_data, key, item) => {
				let key2, item2
				if(Array.isArray(item)) {
					for(key2 in item) {
						item2 = item[key2]
						recursed(form_data, key + "[]", item2)
					}
				}
				else if(typeof item === 'object') {
					for(key2 in item) {
						item2 = item[key2]
						recursed(form_data, key + "[" + key2 + "]", item2)
					}
				} else {
					form_data.append(key, item)
				}
			}
	
			let form_data = new FormData();
			let key;
			for(key in form_info){
				let item = form_info[key]
				recursed(form_data, key, item)
			}
			return form_data
		}
	}

	document.quick_xhr = function(url, form_info = {}, method = "POST", xhr_change_f = undefined) {
		return new Promise(function(resolve, reject) {
			var xhr, form_data, csrf_content;
			
			xhr = new XMLHttpRequest();
			xhr.withCredentials = false
			xhr.open(method, url)
			xhr.onload = function(){
				if(xhr.status >= 200 && xhr.status <= 302 && xhr.status != 300) {
					// console.log(xhr.status)
					// console.log(xhr)
					if(xhr.responseText) {
						let i = JSON.parse(xhr.responseText)
						i['status'] == xhr.status
						// console.log('quick_xhr_output', i)
						resolve(i)
					} else {
						resolve()
					}
				} else {
					reject({status: xhr.status, statusText: xhr.statusText});
				}
			}
			xhr.onerror = function() {
				reject({
					status: xhr.status,
					statusText: xhr.statusText
				});
			}

			//get form_info into the form_data
			form_data = document.obj_to_fd(form_info)

			//csrf
			csrf_content = document.querySelector('meta[name="csrf-token"]').content

			xhr.setRequestHeader('X-CSRF-Token', csrf_content)

			//helper so we know where this came from
			form_data.append('quick_xhr', 'true')

			//add a file or something if you want go nuts
			if(!(xhr_change_f === undefined)) {
				xhr = xhr_change_f(xhr)
			}

			let conf = form_data.get('confirm')
			if(conf == null) {
				xhr.send(form_data)
			} else {
				conf = confirm(conf)
				if(conf == null || conf == false) {
					resolve({'returning': 'user rejected confirm prompt'})
				} else {
					xhr.send(form_data)	
				}
			}
		})
	}

	document.in_sub_site = function(hostname){
		if(hostname == undefined){hostname = window.location.hostname}
		return ( hostname.split('.').length - 1 ) > 1
	}

	document.in_main_site = function(hostname){
		return !(document.in_sub_site(hostname))
	}

	document.is_local_url = function(url) {
		//local includes subdomains. So if we are on x.com, x.com will work and y.x.com will work, but y.com wont.
		//change the -2 to -3, -4 etc to modify. Times where this may be an issue:
		//	- if you share domains with untrusted partys.
		let urld = (new URL(url, window.location.origin)).hostname
		let d = window.location.hostname
		if( d.split('.').slice(-2).join('.') == urld.split('.').slice(-2).join('.')) {
			return true
		}
		return false
	}

	//this is basically discount reflexes that I am using because reflexes cant change cookies.
	//As the name implies, you should probably use reflexes instead if you can. Although i will say
	//that this is quite a bit simpler in some ways.
	document.tripwire = function(url, opts = {}){
		//tripwire can be a security risk as its so dynamic, so make sure we are only connecting with ourselves...
		let form_info = opts['form_info']
		if(form_info == undefined){form_info = {}}
		let method = opts['method']
		if(method == undefined){method="POST"}
		let xhr_change_f = opts['xhr_change_f']
		let e = opts['e']
		let base_element = opts['base_element']
		let submitter = opts['submitter']
		if(e != null) {
			if(base_element == null) {
				base_element = e.currentTarget
			}
			if(submitter == null) {
				submitter = e.submitter
			}
		}
		
		if(! document.is_local_url(url)){ throw url + " is not a local_url (which is a requirement for tripwire for security reasons)"	}
	
		let handle_response = (actions, resolve, reject) => {
			let y, ta, directive, data, out, returning, trip_id, action
			
			returning = action
	
			if(!Array.isArray(actions)) {
				actions = [actions]
			}
			
			y = 0
			ta = new TripwireActions(base_element, submitter, resolve, reject, is_dev)
			// if(actions[0]['returning'] == null) {
			// 	console.log(actions)
			// }
			for(action of actions) {
				trip_id = action.trip_id
				if(trip_id != null) {
					if( document.tripwire_previous_trips.includes(trip_id) ) {
						continue;
					}
					document.tripwire_previous_trips.push(trip_id)
					delete action.trip_id
				}
				directive = Object.keys(action)[0]
				data = action[directive]
				if(directive == 'eval'){directive = 'eval2'}
				if(directive == 'returning') {
					returning = data
				} else {
					try {
						out = ta[directive](data)
						if(is_dev){
							console.log(directive, data)
						}
					} catch(err) {
						if(is_dev){
							console.log(directive, data, err, err.message)
						}
					}
					if(out == "break"){
						break
					}
				}
			}
			resolve(returning)
		}
	
		return new Promise(function(resolve, reject) {
			//we_are_using_http = there_is_a_connection and polling_is_ok. polling_is_ok will be based
			//	on promises calling promises that are constantly sending out rest requests every x seconds
			//	and waiting y seconds.
			
			//ability to use http over cable should be switchable in a hidden form parameter
			//we should also have polling to make sure the connection is still open
			//if(we_are_using_http)
			document.quick_xhr(url, form_info, method, xhr_change_f).then((obj) => {
				handle_response(obj, resolve, reject)
			}).catch((e) => {
				reject(e)
			})
			//else if(we are using cable)
			//  chatChannel.send({ sent_by: "Paul", body: "This is a cool chat app." })
			//  or in my application...
			//  application.user_sub.send({whatever information we have in a jsony format})
		})
	}

	document.tripwire_rails_forms = (method, form_data) => {
		// https://guides.rubyonrails.org/form_helpers.html
		// see 2.3 and _method information. If you are not using rails,
		//the your function may look like: document.tripwire_handle_formmethod(method){ return method }

		method = method.toUpperCase()
		let rails_overrides = ["PATCH", "PUT", "DELETE"]
		if(rails_overrides.includes( method.toUpperCase() )) {
			form_data.set("_method", method)
			return "POST"
		} else {
			// form_data.delete("_method")
			return method
		}
	}
	
	//this makes forms act as expected for a regular form using tripwire
	document.tripwire_get_data = (f, sub) => {
		let nm, url, fd, meth
		url = sub.getAttribute('formaction')
		meth = sub.getAttribute('formmethod')
		if(url == null){ url = f.getAttribute('action') }
		if(meth == null){ meth = f.getAttribute('method') }
		if(meth == null){ meth = "POST" }
	
		//what to send?
		fd = new FormData(f)
		meth = document.tripwire_rails_forms(meth, fd)
		nm = sub.getAttribute('name')

		//add signifier of button pressed if so wished
		if(nm != null) {
			fd.set(nm, sub.value)
		}
	
		return [url, meth, fd]
	}
	
	document.tripwire_intercept = (e) => { //for submit events
		let f, sub
		e.preventDefault();
	
		// where/how to send?
		f = e.currentTarget
		if(f == null){f = e.target} //<- not really sure why this happens sometimes
		
		if(e.type != 'submit'){console.log('unexpected event type (should be submit)')}
		sub = e.submitter
		console.log(sub, e.currentTarget, e)
		let [url, meth, fd] = document.tripwire_get_data(f, sub)

		return document.tripwire(url, {
			form_info: fd,
			method: meth,
			e: e
		})
	}

	document.tripwire_previous_trips = []
	document.tripwire_active_poll_id = null

	document.tripwire_poll = (dat) => {
		if(document.tripwire_active_poll_id != dat.id) { return } //check if we should stop

		// console.log(document.tripwire)
		document.tripwire('/api/poll', {
			form_info: {
				'tripwire-store': dat.store
				//add the encrypted data we need with the queue strings
			}
		}).then((x)=>{
			if(x == 'should_resub') {
				document.tripwire_subscribe(dat.queues, dat.poll_info, dat.wait_time)
			} else if(!(x == "no_updates")) {
				dat.store = x
			}
		//annoying error during development or when offline
		}, (e)=>{}).finally(()=>{
			setTimeout(()=>{
				document.tripwire_poll(dat)
			}, dat.wait_time)
		})
	}
	document.tripwire_subscribe = (queues, poll_info, wait_time) => {
		let id = Math.random()
		document.tripwire_active_poll_id = id
		let dat = {
			queues: queues,
			poll_info: poll_info,
			wait_time: wait_time,
			id: id
		}
		// console.log(document.tripwire)
		document.tripwire('/api/pollsub', {
				form_info: {
					queues: dat.queues,
					...dat.poll_info
				}
			}).then((out) => {
				if(out == 'no_queues') {
					if(is_dev){console.log('no_queues', out)}
				} else {
					dat.store = out
					document.tripwire_poll(dat)
				}
			}, (x)=>{
				if(is_dev){console.log('sub fail', x)}
			})
	}
}
export default tripwire_init