import { Controller } from 'stimulus'
// import StimulusReflex from 'stimulus_reflex'
export default class extends Controller {
	controller_dataset(e){
		//gets the data attributes related to an event for this controller.
		//note it turns name "data-all-good" into "allGood" which is strange
		//but workable.

		//it also picks up ids because that makes life easier. This was a later addition.
		let ds = { base_id: this.element.id, submitter_id: e.currentTarget.id, ...this.element.dataset, ...e.currentTarget.dataset }

		if(ds['dsObjs']){
			for(let o of Array.from(document.querySelectorAll(ds['dsObjs']))){
				ds = {...o.dataset, ...ds} //ds takes priority
			}
		}

		return ds
	}
	tripwire_below_throttled(e, is_timeout, ct){
		//if not from timeout get current target and dataset. Otherwise we get from timeout
		if(is_timeout == undefined){
			e.preventDefault();
			e.stopPropagation();
			ct = e.currentTarget
			if(this.tdt_dataset == undefined){this.tdt_dataset = {}}
			this.tdt_dataset[ct] = this.below_inputs(e)
		}
		//if we are waiting for a throttle to finish, do nothing. We updated data above.
		if(this.tdt_timeout != undefined && is_timeout == undefined){
			return
		//if there were no further actions over the throttle period, we need to stop and reset
		//the timeout for next time
		} else if (this.tdt_dataset[ct] == undefined && is_timeout == true) {
			this.tdt_timeout = undefined
			return
		}

		let { dat, method } = this.tdt_dataset[ct]

		document.tripwire(dat.get('path'),  {
			method: method,
			form_info: dat,
			submitter: ct,
			base_element: this.element
		})

		this.tdt_dataset[ct] = undefined
		this.tdt_timeout = setTimeout(this.tripwire_below_throttled.bind(this), 500, undefined, true, ct)
	}
	tripwire_data(e){
		//gets path from data-path variable and uses the POST method
		//to tripwire the data-sets of the controllers element and the submitters element
		e.preventDefault();
		e.stopPropagation();

		let dat = this.controller_dataset(e)

		let method
		if(dat['method']) {
			method = dat['method']
		} else {
			method = 'POST'
		}

		return document.tripwire(dat['path'],  {
			method: method,
			form_info: dat,
			submitter: e.currentTarget,
			base_element: this.element
		})
	}
	below_inputs(e){
		//data bariables to FormData
		
		let dat = this.controller_dataset(e)
		dat = document.obj_to_fd(dat)

		//get below inputs appended to the FormData
		let inp
		for(inp of this.element.querySelectorAll('input')) {
			if(inp.name != null && inp.value != null) {
				dat.append(inp.name, inp.value)
			}
		}

		//get self appended if we are input to the FormData
		if(this.element.nodeName == 'INPUT') {
			inp = this.element
			if(inp.name != null && inp.value != null) {
				dat.append(inp.name, inp.value)
			}
		}

		//get the method.
		let method
		if(dat.get('method')) {
			method = dat.get('method')
		} else {
			method = 'POST'
		}

		return {dat, method}
	}
	tripwire_below(e){
		//like the tripwire_data method, but also throws in any input variables
		//for good measure. A nice all rounder one may say. Good if you want ajaxy elements within a form
		//that use some but not all of the forms inputs.

		e.preventDefault();
		e.stopPropagation();

		let { dat, method } = this.below_inputs(e)

		return document.tripwire(dat.get('path'), {
			method: method,
			form_info: dat,
			submitter: e.currentTarget,
			base_element: this.element
		})
	}
	connect() {
	}
	tripwire_intercept(e) {
		return document.tripwire_intercept(e)
	}
	tripwire_intercept_tiptap(e) {
		midflip.save_tiptaps()
		return this.tripwire_intercept(e)
	}
	best_intercept(e){ // < you can tell that my smashed together... not sure i can call it a framework, is not quite holding up lol
		midflip.save_tiptaps()
		this.tripwire_below(e).finally((() => {
			midflip.kill_any_loading_butts_below(this.element)
		}).bind(this))
	}
	intercept_with_extra(e) {
		midflip.save_tiptaps()
		this.tripwire_intercept(e).finally((() => {
			midflip.kill_any_loading_butts_below(context.element)
		}).bind(this))
	}
	disconnect(){}
}